import React,{useState,useEffect,useRef} from 'react'
import image from '../assets/person.jpg'
import '../App.css'
import { FaFacebookF } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Modal from "@mui/material/Modal";
import QRCode from "qrcode.react";
import share from "../assets/pngwing.com (9).png";
import copy from "../assets/pngwing.com (11).png";
import { RWebShare } from "react-web-share";
import { IoShareSocial } from "react-icons/io5";
import { pdfjs } from "react-pdf";
import qr from "../assets/pngwing.com (10).png";
import { useLocation } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { PiUploadSimpleBold } from "react-icons/pi";
import { Box, SpeedDial } from '@mui/material';
import tinycolor from "tinycolor2";
import Pdfi from '../assets/PDF-icon.png'
import { IoIosPhonePortrait } from "react-icons/io";
import { MdAlternateEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress"; 
import { BsFiletypePdf } from 'react-icons/bs';
const Template9 = () => {
    const [oppen1, setOppen1] = React.useState(false);
    
  const [oppen, setOppen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userData, setUserData] = useState(null);
  const [urlToShare, seturlToShare] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state variable for loading status
  const [opennnn, setOpennnn] = useState(false);
  const handleCloseeee = () => setOpennnn(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let retryCount = 0;
  const maxRetryCount = 3; // Maximum number of retries
  const retryDelay = 2000; // Delay between retries in milliseconds
  const getCurrentUrl = () => {
    seturlToShare(window.location.href);
  };
  const retryFetch = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => {
        fetchData();
        retryCount++;
      }, retryDelay);
    } else {
      console.error("Maximum retry attempts reached.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUrl();
  }, []);
  const opene = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const speedDialRef = useRef();
  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const userParam = query.get("user");

  console.log("userParam", userParam);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axios.get(
          `https://api.tebsocial.com/api/user/${userParam}`
        );

        setUserData(response.data.data);
        console.log(response.data.data);
        document.documentElement.style.setProperty(
          "--bg-color",
          response.data.data.bgColor
        );
        document.documentElement.style.setProperty(
          "--button-bg-color",
          response.data.data.buttonColor
        );
        document.documentElement.style.setProperty(
          "--border-color",
          response.data.data.buttonColor
        );

        if (apicounter == 0) {
          axios.post(
            `https://api.tebsocial.com/api/profile/${response.data.data?.id}/visit`,
            {}
          );
          apicounter++;
          console.log(apicounter);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.error("Server responded with error:", error.response.status);

          setIsLoading(false);
        } else if (error.request) {
          console.error("No response received:", error.request);
          retryFetch();
        } else {
          console.error("Error setting up request:", error.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [userParam]);
  let apicounter = 0;

  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");

  useEffect(() => {
    const mainBackgroundColor = userData?.bgColor;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.bgColor]);

  useEffect(() => {
    const buttonsBackground = userData?.buttonColor;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.buttonColor]);
  const saveAsPDF = async () => {
    const targetComponent = document.getElementById("mainComponent");
    if (targetComponent) {
      try {
        const canvas = await html2canvas(targetComponent);
        const imgData = canvas.toDataURL("image/png");

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const { width, height } = page.getSize();
        const img = await pdfDoc.embedPng(imgData);
        const { width: imgWidth, height: imgHeight } = img.scale(1);

        page.drawImage(img, {
          x: 0,
          y: 0,
          width: imgWidth,
          height: imgHeight,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Use FileSaver.js library to save the blob as a file
        const saveAs = require("file-saver");
        saveAs(blob, "screenshot.pdf");
      } catch (error) {
        console.error("Error saving as PDF:", error);
      }
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const params = new URLSearchParams(window.location.search);

  const profileValue = params.get("profile");

  console.log("Profile:", profileValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopyLink = () => {
    const linkToCopy = urlToShare;

    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
    // toast.success('Link copied!');

    console.log("Link copied to clipboard:", linkToCopy);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 5000 milliseconds (5 seconds)

    // You can add additional logic or feedback here if needed
  };

  const handleDocumentClick = (e) => {
    if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
      // Click occurred outside the SpeedDial, hide it
      setOppen(false);
    }
  };
  const toDataURL = (url) =>
    axios
      .get(url, {
        responseType: "blob", // Important to handle the response as a Blob
        headers: {},
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(response.data);
        });
      })
      .catch((error) => {
        console.error("Error in toDataURL with axios:", error);
        return null;
      });

  const saveToContacts = async () => {
    const contact = {
      name: userData?.firstName + " " + userData?.lastName,
      mobile: userData?.phoneNum || "",
      email: userData?.email || "",
      address: userData?.location || "",
      organization: userData?.businessName || "",
      TebSocialWebsite: urlToShare,
      photo: userData?.photo || "",
      primary_links: userData?.primary_links || [],
    };

    let imageDataUrl = "";
    if (contact.photo !== "") {
      imageDataUrl = await toDataURL(contact.photo);
      console.log("imageDataUrl,imageDataUr", imageDataUrl);
      if (!imageDataUrl) {
        console.error("Failed to convert image to Data URL");
        return;
      }
    }

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `item1.URL:${contact.xTagWebsite}\n` +
      `item1.X-ABLabel:Teb Social WEBSITE\n` +
      `PHOTO;ENCODING=b;TYPE=JPEG:${imageDataUrl.split(",")[1]}\n`;

    contact?.primary_links?.forEach((link, index) => {
      const itemNumber = index + 2; // Starting from 2 as 1 is used for TebSocialWebsite
      vcard +=
        `item${itemNumber}.URL:${link.value}\n` +
        `item${itemNumber}.X-ABLabel:${link.name}\n`;
    });

    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contact.name}.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID

    try {
      await axios.post(
        `https://api.tebsocial.com/api/link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  const handlePrimaryLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID
console.log('primaryItem',primaryItem)
    try {
      await axios.post(
        `https://api.tebsocial.com/api/${userData.id}/primary_link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress /> {/* Loading spinner */}
      </div>
    );
  }
      const social = [
    {icon:<FaFacebookF/> , name:"098573874"},
    {icon:<svg xmlns="http://www.w3.org/2000/svg" width="20.186" height="20.186" viewBox="0 0 48.186 48.186"> <path id="linkedin-svgrepo-com" d="M12,15.077V48.186H0V15.077Zm18.091-.061v1.951a11.818,11.818,0,0,1,6.836-1.788c9.978,0,11.257,7.747,11.257,14.495V48.185H36.125V30.6c0-2.958-1.058-3.519-3.015-3.519-1.861,0-3.015.591-3.015,3.519V48.185H18.032V15.016ZM6,0A6,6,0,1,1,0,6,6.007,6.007,0,0,1,6,0Z" fill="#126bc4" fill-rule="evenodd"/></svg> , name:"you@email.com"},
    {icon:<svg xmlns="http://www.w3.org/2000/svg" width="20.447" height="20.496" viewBox="0 0 63.447 51.496"><path id="Twitter" d="M55.97,8.142A13.044,13.044,0,0,0,61.695.95,26.124,26.124,0,0,1,53.429,4.1,13.024,13.024,0,0,0,31.247,15.962,36.963,36.963,0,0,1,4.417,2.379,13,13,0,0,0,8.444,19.735a12.979,12.979,0,0,1-5.9-1.625c0,.054,0,.107,0,.163A13.013,13.013,0,0,0,12.989,31.02a13.045,13.045,0,0,1-5.879.223,13.029,13.029,0,0,0,12.161,9.028A26.138,26.138,0,0,1,3.1,45.837,26.113,26.113,0,0,1,0,45.653,36.863,36.863,0,0,0,19.954,51.5C43.9,51.5,56.99,31.685,56.99,14.505q0-.843-.038-1.682a26.4,26.4,0,0,0,6.5-6.728A26.063,26.063,0,0,1,55.97,8.142Z" fill="#1d9bf0"/></svg> , name:"www.yourwebsite.com"},
    {icon:<svg xmlns="http://www.w3.org/2000/svg" width="20.408" height="20.778" viewBox="0 0 28.408 56.778"><path id="Facebook" d="M28.408,18.383H18.737V12.491a2.493,2.493,0,0,1,2.687-2.728H28.25V.035L18.85,0C8.409,0,6.034,7.258,6.034,11.9v6.482H0V28.411H6.034V56.778h12.7V28.411H27.3Z" fill="#106aff"/></svg>, name:"123.street,city"},
    {icon:<svg xmlns="http://www.w3.org/2000/svg" width="20.357" height="20.714" viewBox="0 0 49.357 40.714"><g id="Telegram_logo" transform="translate(0 0)"><path id="Path_105966" data-name="Path 105966" d="M81.229,99.482l5.419,15s.677,1.4,1.4,1.4,11.516-11.225,11.516-11.225l12-23.176L81.422,95.61Z" transform="translate(-69.191 -76.286)" fill="#c8daea"/><path id="Path_105967" data-name="Path 105967" d="M98.384,138.878l-1.04,11.055s-.435,3.387,2.951,0,6.628-6,6.628-6" transform="translate(-79.161 -111.835)" fill="#2aa1da"/><path id="Path_105968" data-name="Path 105968" d="M61.739,91.559,50.592,87.927s-1.332-.54-.9-1.766c.088-.253.266-.468.8-.837C52.958,83.6,96.2,68.059,96.2,68.059a3.684,3.684,0,0,1,1.941-.138,1.053,1.053,0,0,1,.717.782,3.56,3.56,0,0,1,.1.984c0,.286-.038.552-.064.968-.263,4.25-8.145,35.965-8.145,35.965s-.472,1.856-2.161,1.919a3.094,3.094,0,0,1-2.255-.872c-3.316-2.852-14.775-10.553-17.308-12.247a.484.484,0,0,1-.208-.343c-.035-.179.159-.4.159-.4s19.954-17.737,20.485-19.6c.041-.144-.114-.215-.323-.152-1.325.488-24.3,15-26.836,16.6A1.222,1.222,0,0,1,61.739,91.559Z" transform="translate(-49.603 -67.828)" fill="#2aa1da"/></g></svg> , name:"www.yourwebsite.com"},
    {icon:<FaTelegramPlane/> , name:"123.street,city"},

  ]

  const pdfs = [
    { url: 'https://example.com/pdf1.pdf', name: 'PDF 1' },
    { url: 'https://example.com/pdf2.pdf', name: 'PDF 2' },

  ]



  return (
   <div className='h-screen overflow-y-scroll ' style={{fontFamily:"mvboli"}}>
   <div
   className="fixed w-screen h-screen -z-10 "
   style={{ backgroundColor: userData?.bgColor }}
 ></div>
      <div className='relative flex justify-center'
      style={{
            backgroundImage: `url(${userData?.photo})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "60vh",
            zIndex: 10,
            transition: '0.5s filter linear',
            // margin:"10px",
            // borderRadius:"30px"
          }}
          >
         <div className="md:w-[88%] w-[100%] absolute -left-20 mt-64 h-48 skew-container z-10" style={{background:userData?.buttonColor}}>
            <div className='absolute z-30 left-24 skew-containers text-white mt-3'>
                <h2 className='text-3xl font-semibold w-[50%] -ml-1' style={{fontFamily:"mvboli" , color:textbottoncolor}}>  {userData?.businessName !=="undefined" && userData?.businessName !=="null" &&
                userData?.businessName !==undefined && userData?.businessName !==null? userData?.businessName : ""}{" "}
    
             </h2>
                 <div className="grid grid-cols-1 gap-1 mt-2 -ml-2">
                <div className='flex justify-start w-[100%] pl-2 gap-2'
                onClick={() => {
          const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
          window.open(`tel:${cleanedPhoneNum}`);
          window.open(`tel:${userData.phoneNum}`);}}>
                    <p className='mt-1'><IoIosPhonePortrait color={textbottoncolor}/></p>    
                    <p className='' style={{fontFamily:"mvboli" , color:textbottoncolor}}>{userData?.phoneNum}</p>
                </div>
                <div className='flex justify-start w-[100%] pl-2 gap-2'
                onClick={() => {
          window.open(`mailto:${userData.email}`);
        }}>
                    <p className='mt-1'><MdAlternateEmail color={textbottoncolor}/></p>   
                    <p className='' style={{fontFamily:"mvboli" , color:textbottoncolor}}>{userData.email}</p>
                </div>
                <div className='flex justify-start w-[100%] pl-2 gap-2'
                onClick={() => {
          const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
          window.open(`https://wa.me/${cleanedPhoneNum}`);
        }}>
                    <p className='mt-1 '><FaWhatsapp color={textbottoncolor}/></p>   
                    <p className='' style={{fontFamily:"mvboli" , color:textbottoncolor}}>{userData?.phoneNum}</p>
                </div>
                <div className='flex justify-start w-[100%] pl-2 gap-2'>
                    <p className='mt-1'><SlLocationPin color={textbottoncolor}/></p>   
                    <p className='' style={{fontFamily:"mvboli" , color:textbottoncolor}}>{userData?.location}</p>
                </div>
              </div>
            </div>
         </div>

      </div>
     <div className='mt-28 p-2'>

     {userData?.bio !== null && userData?.bio !== "null" && (
    
      <h2 className=' text-2xl font-semibold' style={{fontFamily:"mvboli" , color:userData?.buttonColor }} >About us</h2> 
      
    )}

       
        <h2 style={{fontFamily:"mvboli"}}>
           {userData?.bio !== null && userData?.bio !== "null" && (
                <p
                  className="mb-10"
                  style={{color:textcolor}}
                >
               {userData?.bio}
                </p>
              )}
        </h2>
        <h2 className=' text-2xl font-semibold mt-3' style={{fontFamily:"mvboli", color:userData?.buttonColor, color:userData?.buttonColor}}>Social Media</h2> 
            
        <div className="grid grid-cols-3 justify-center items-center gap-y-7 mt-20 place-items-center">
        {userData?.primary_links?.map((primaryItem, primaryIndex) => (
    <div key={primaryIndex}>
      <button className="flex justify-between w-[100%]">
        <div className="flex flex-row">
          <div className="w-[50px] h-[50px] flex items-center">
            {primaryItem.name !== "Telegram" ? (
              <a
                 href={primaryItem.value && primaryItem.value.startsWith("http") ? primaryItem.value : `https://${primaryItem.value}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  handlePrimaryLinkClick(primaryItem);
                }}
              >
                <img src={primaryItem.logo} alt="" className="className='w-[50px] h-[50px] rounded-md my-1 " />
              </a>
            ) : (
              <a
                href={`https://t.me/${primaryItem.value}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  handlePrimaryLinkClick(primaryItem);
                }}
              >
                <img
                  src={primaryItem.logo}
                  alt=""
                  className="className='w-[50px] h-[50px] rounded-md my-1 "
                  onClick={() => {
                    const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, "");
                    window.open(`https://t.me/${cleanedPhoneNum}`);
                  }}
                />
              </a>
            )}
          </div>
          
        </div>
      </button>
    </div>
  ))}
  {userData?.second_links?.map((primaryItem, primaryIndex) => (
    <div key={primaryIndex}>
      <button className="flex justify-between w-[100%]">
        <div className="flex flex-row">
          <div className="w-[60px] h-[60px] flex items-center">
              <a
              href={primaryItem?.link}
                              target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  handlePrimaryLinkClick(primaryItem);
                }}
              >
                <img src={primaryItem.logo} alt="" className="className='w-[60px] h-[60px] rounded-md my-1 " />
              </a>
              </div>
        </div>
      </button>
      </div>
            ))}
</div>


        <div className='fixed right-2 rounded-full bottom-2 text-center text-sm flex gap-5 p-2 px-2 z-50' style={{background:userData?.buttonColor}} onClick={saveToContacts}>
        <span className=" whitespace-nowrap text-white" style={{fontFamily:"mvboli" , color:textbottoncolor}}>Add To Contacts</span>        
        </div>
        <button className="fixed z-50 left-1 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)]">
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 260,
                p: 4,
              }}
            >
              <div
                style={{
                  borderRadius: "20px", // Adjust as needed for roundness
                  overflow: "hidden",
                }}
              >
                <QRCode
                  value={urlToShare}
                  size={200}
                  fgColor={textcolor}
                  bgColor={userData?.bgColor}
                  level="M"
                  includeMargin={true}
                  renderAs="svg"
                />
              </div>
            </Box>
          </Modal>
          <Box
            sx={{ height: 1, transform: "translateZ(0px)", flexGrow: 1 }}
            style={{
              position: "fixed",
              bottom: "1px",
              top:"10px ",
              left: "2px",
              whiteSpace: "nowrap",
              color: `white`,
              zIndex: "1000",
            }}
          >
            <SpeedDial
              onClick={() => setOppen(!oppen)}
              ariaLabel="SpeedDial basic example"
              sx={{
                position: "fixed",
                bottom: 11,
                left: 0,
                zIndex: 1000,
                "& .MuiSpeedDial-fab": {
                  backgroundColor: `${userData?.buttonColor} !important`,
                },
              }}
            //   style={{ borderColor: textcolor }}
              icon={
                <div
                  style={{
                    // border: "2px solid",
                    // borderColor: textbottoncolor,
                    borderRadius: "50%",
                    padding: "12px",
                  }}
                >
                  <PiUploadSimpleBold size={28} color={textbottoncolor} />
                </div>
              }
              ref={speedDialRef}
              open={oppen}
            >
              <SpeedDialAction
                icon={
                  <div className="action-item" onClick={handleOpen}>
                    <img src={qr} className="action-icon" alt="QR CODE" />
                    <span className=" text-xs text-black">MY QR</span>
                  </div>
                }
              />

              <SpeedDialAction
                icon={
                  <div className="action-item" onClick={handleCopyLink}>
                    <img src={copy} className="action-icon" alt="Copy" />
                    <span className="action-text">COPY</span>
                  </div>
                }
                onClick={handleCopyLink}
              />

              <div
                className={`${
                  oppen
                    ? "absolute action-item left-[-70%] w-20 h-10 -top-12 z-30 bg-white rounded-3xl text-[var(--button-text-color)] font-bold text-lg"
                    : "hidden"
                }`}
                style={{ zIndex: "1000" }}
                onClick={() => setOppen(!oppen)}
              >
                <RWebShare
                  data={{
                    url: urlToShare,
                    title: "Share your TebSocial link",
                  }}
                  onClick={() => setOppen(!oppen)}
                >
                  <div className="flex -ml-4 -mt-2">
                    <img src={share} className="w-5 h-5 mt-3 ml-2 z-50" />
                    <span className="  font-normal text-xs mt-3 ml-1 action-text ">
                      SHARE
                    </span>
                  </div>
                </RWebShare>
              </div>
            </SpeedDial>
          </Box>
        </button>

     
 <div className="flex justify-center items-center mt-10 " style={{ backgroundColor: userData?.bgColor }}>
 <div className="w-[70%] flex flex-col gap-3">
   {userData?.section.map((item, index) => {
     const url = item.media;
     const isPDF = url.endsWith(".pdf");

     return (
       <>
         {!isPDF && (
           <div className="flex justify-center">
             <h2
               className="text-2xl md:text-3xl font-bold"
               style={{color:userData?.buttonColor }}
             >
               {item.title}
             </h2> 
          
           </div>
         )}

         <div
           key={index}
           className="flex flex-col gap-2 justify-center items-center"
           style={{ backgroundColor: userData?.bgColor }}
         >
           {isPDF ? (
            <div className="flex flex-col items-center justify-center mb-14">
            <h2
              className="text-2xl md:text-3xl p-4"
              style={{ color: textcolor }}
            >
              {item.title}
            </h2>
            <div className='w-[250px]  rounded-[10px] border-4' style={{backgroundColor:userData?.buttonColor,borderColor:textcolor,borderRadius: "20px"}}>
            <a href={url} target='_blank'  >
                <div className='flex justify-center  w-full    shadow-lg  relative'  >
                <div className='flex flex-row gap-5'>
              <BsFiletypePdf
                              size={90}
                              className="w-full mt-5 mb-5 "
                              color={textbottoncolor}
                            />

                </div>
                <div className='absolute top-3 right-3'>
                <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                <path fill={textbottoncolor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                </svg>
                </div>
                </div>
            </a>
        </div>

          </div>


           ) : (
             <div className=" flex justify-center" style={{ backgroundColor: userData?.bgColor }}>
               <video
                 className={`rounded-3xl border-4 min-w-[250px] h-[150px]  aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                 style={{
                   borderColor: `${textcolor}`,
                   backgroundColor: `${userData?.buttonColor}`,
                 }}
                 controls
               >
                 <source src={url} type="video/mp4" className="" />
               </video>
             </div>
           )}
         </div>
       </>
     );
   })}
 </div>
</div>



    </div>
    
    </div>
)
}

export default Template9
