import React,{useState,useEffect,useRef} from 'react'
import image from '../assets/person.jpg'
import Pdfi from '../assets/PDF-icon.png'
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { FaPlus } from "react-icons/fa";
import { IoShareSocial } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import qr from "../assets/pngwing.com (10).png";
import QRCode from "qrcode.react";
import share from "../assets/pngwing.com (9).png";
import copy from "../assets/pngwing.com (11).png";
import { RWebShare } from "react-web-share";
import { IoQrCodeOutline } from "react-icons/io5";
import { PiUploadSimpleBold } from "react-icons/pi";
import { Box, SpeedDial } from '@mui/material';
import { FaPhone } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { IoLogoWhatsapp } from "react-icons/io5";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress"; 
import tinycolor from "tinycolor2";
import { BsFiletypePdf } from 'react-icons/bs';
import { AiOutlineMail } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6"
import { FaWhatsapp } from "react-icons/fa6";
import powerdByXTag from '../assets/poweredByXtag.png'
import powerdByXTagWhite from '../assets/poweredbyxtagWhite.png'

import powerdByXTagBlack from '../assets/poweredbyxtagBlack.png'

const Temp18 = (userData) => {
  // const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [textcolor, setTextColor] = useState("#ffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");
  const [open, setOpen] = React.useState(false);
  const [urlToShare, seturlToShare] = useState(null);
  const speedDialRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [animate, setAnimate] = useState(false);

  const getCurrentUrl = () => {
    seturlToShare(window.location.href);
  };
  const retryFetch = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => {
        fetchData();
        retryCount++;
      }, retryDelay);
    } else {
      console.error("Maximum retry attempts reached.");
      setIsLoading(false);
    }
  };
  const location = useLocation();

  const [oppen, setOppen] = React.useState(false);

  useEffect(() => {
    getCurrentUrl();
  }, []);

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const userParam = query.get("user");


  useEffect(() => {
    const mainBackgroundColor = userData?.bgColor;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      //setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.bgColor]);

  useEffect(() => {
    const buttonsBackground = userData?.buttonColor;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.buttonColor]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axios.get(
          `https://api.tebsocial.com/api/user/${userParam}`
        );

        setUserData(response.data.data);
        console.log(response.data.data);
        document.documentElement.style.setProperty(
          "--bg-color",
          response.data.data.bgColor
        );
        document.documentElement.style.setProperty(
          "--button-bg-color",
          response.data.data.buttonColor
        );
        document.documentElement.style.setProperty(
          "--border-color",
          response.data.data.buttonColor
        );

        if (apicounter == 0) {
          axios.post(
            `https://api.tebsocial.com/api/profile/${response.data.data?.id}/visit`,
            {}
          );
          apicounter++;
          console.log(apicounter);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.error("Server responded with error:", error.response.status);

          setIsLoading(false);
        } else if (error.request) {
          console.error("No response received:", error.request);
          retryFetch();
        } else {
          console.error("Error setting up request:", error.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [userParam]);
  let apicounter = 0;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopyLink = () => {
    const linkToCopy = urlToShare;

    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
    // toast.success('Link copied!');

    console.log("Link copied to clipboard:", linkToCopy);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 5000 milliseconds (5 seconds)

    // You can add additional logic or feedback here if needed
  };

  const handleDocumentClick = (e) => {
    if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
      // Click occurred outside the SpeedDial, hide it
      setOppen(false);
    }
  };
  const toDataURL = (url) =>
    axios
      .get(url, {
        responseType: "blob", // Important to handle the response as a Blob
        headers: {},
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(response.data);
        });
      })
      .catch((error) => {
        console.error("Error in toDataURL with axios:", error);
        return null;
      });

  const saveToContacts = async () => {
    const contact = {
      name: userData?.firstName + " " + userData?.lastName,
      mobile: userData?.phoneNum || "",
      email: userData?.email || "",
      address: userData?.location || "",
      organization: userData?.businessName || "",
      TebSocialWebsite: urlToShare,
      photo: userData?.photo || "",
      primary_links: userData?.primary_links || [],
    };

    let imageDataUrl = "";
    if (contact.photo !== "") {
      imageDataUrl = await toDataURL(contact.photo);
      console.log("imageDataUrl,imageDataUr", imageDataUrl);
      if (!imageDataUrl) {
        console.error("Failed to convert image to Data URL");
        return;
      }
    }

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `item1.URL:${contact.xTagWebsite}\n` +
      `item1.X-ABLabel:Teb Social WEBSITE\n` +
      `PHOTO;ENCODING=b;TYPE=JPEG:${imageDataUrl.split(",")[1]}\n`;

    contact?.primary_links?.forEach((link, index) => {
      const itemNumber = index + 2; // Starting from 2 as 1 is used for TebSocialWebsite
      vcard +=
        `item${itemNumber}.URL:${link.value}\n` +
        `item${itemNumber}.X-ABLabel:${link.name}\n`;
    });

    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contact.name}.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID

    try {
      await axios.post(
        `https://api.tebsocial.com/api/link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  const handlePrimaryLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID
console.log('primaryItem',primaryItem)
    try {
      await axios.post(
        `https://api.tebsocial.com/api/${userData.id}/primary_link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
    <div className='absolute top-0 left-0 w-full h-full' style={{ zIndex: 100 }}>
    <img 
      src={userData?.photo} 
     
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        objectFit: 'cover', // This will ensure the image covers the entire element
        objectPosition: 'center' // Centers the image correctly
      }}
    />
  </div>
  
      <div className='fixed w-full h-full' style={{ backgroundColor: '#619A98', opacity: "0.5" , zIndex:"200"}}></div>
      <div className='absolute  top-1/3 left-0 right-0   flex flex-col items-center justify-center p-5 pb-[200px]' style={{ zIndex:"400"}}>
        {/* User Info and Social Media Links */}
        <div className='flex flex-col items-center gap-4 mb-5'>
          <p className='text-3xl font-bold' style={{ fontFamily: "Acumin-BdItPro", color: textcolor }}>

            {userData?.firstName !== "undefined" && userData?.firstName !== "null" &&
            userData?.firstName !== undefined && userData?.firstName !== null ? userData?.firstName : ""}{" "}

            {userData?.lastName !== "undefined" && userData?.lastName !== "null" &&
            userData?.lastName !== undefined && userData?.lastName !== null ? userData?.lastName : ""}{" "}

          </p>
          <div>


            {userData?.jobTitle && <h2 className="font-semibold" style={{ color: textcolor }}>
            {userData?.jobTitle !== null &&
              userData?.jobTitle !== "null" &&
              userData?.jobTitle !== "undefined" &&
              userData?.jobTitle !== undefined ? (
                userData.jobTitle
          ) : (
              ""
          )}
            </h2>}

          
            {userData?.businessName && <h2 className="text-sm" style={{ color: textcolor }}>
            {userData?.businessName !== null &&
              userData?.businessName !== "null" &&
              userData?.businessName !== "undefined" &&
              userData?.businessName !== undefined ? (
                userData.businessName
          ) : (
              ""
          )}
            </h2>}
            {userData?.location && <h2 className="text-sm" style={{ color: textcolor }}>
            {userData?.location !== null &&
              userData?.location !== "null" &&
              userData?.location !== "undefined" &&
              userData?.location !== undefined ? (
                userData.location
          ) : (
              ""
          )}
            </h2>}

          

            
          </div>
          <div className='flex gap-3'>
            <div onClick={() => {
              const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
              window.open(`tel:${cleanedPhoneNum}`);
              window.open(`tel:${userData.phoneNum}`);
          }} className='py-3 px-3 rounded-full bg-opacity-50' style={{ backgroundColor: userData?.buttonColor }}>
              <FaPhone color={textbottoncolor} size={22} />
            </div>
            <div onClick={() => {
              window.open(`mailto:${userData.email}`);
          }} className='py-3 px-3 rounded-3xl bg-opacity-50' style={{ backgroundColor: userData?.buttonColor }}>
              <AiOutlineMail color={textbottoncolor} size={22} />
            </div>
            <div   onClick={() => {
              const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
              window.open(`https://wa.me/${cleanedPhoneNum}`);
          }} className='py-3 px-3 rounded-3xl bg-opacity-50' style={{ backgroundColor: userData?.buttonColor }}>
              <FaWhatsapp color={textbottoncolor} size={22} />
            </div>
          </div>
        </div>
        {/* Additional Sections and Links */}
        <div className='flex flex-col items-center w-full max-w-screen-lg'>
            <div className="w-screen flex justify-center" style={{ backgroundColor: 'transparent' }}>
            <div className="py-5 w-[90%]">
                <div className="grid grid-cols-3 justify-center items-center gap-y-7 mt-20 place-items-center">
                

      
                </div>
            </div>
        </div>
   
     
        <div onClick={saveToContacts}


        style={{ backgroundColor: userData?.buttonColor ,color: textbottoncolor,border:"2px solid",borderColor:textbottoncolor}}
       className='flex items-center  fixed right-2 rounded-full mb-2 bottom-[35px]  text-center text-xs px-1 z-50'>
       <div 
       style={{ backgroundColor: userData?.buttonColor,borderColor:textbottoncolor}}
           className='w-fit py-3 px-3 rounded-full -ml-2 border-2  p-1'><FaPlus color={userData?.textbottoncolor} size={19} className=''/></div>
             <p
             
             className=" whitespace-nowrap  pl-[2px] text-[14px] font-bold " style={{color: textbottoncolor,fontWeight:'bold'}}>Add To Contacts</p>   
             </div>




             <button className="fixed z-50 bottom-4 left-1 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)]">
             <Modal
                 open={open}
                 onClose={handleClose}
                 aria-labelledby="modal-modal-title"
                 aria-describedby="modal-modal-description"
             >
                 <Box
                     sx={{
                         position: "absolute",
                         top: "50%",
                         left: "50%",
                         transform: "translate(-50%, -50%)",
                         width: 260,
                         p: 4,
                     }}
                 >
                     <div
                         style={{
                             borderRadius: "20px", // Adjust as needed for roundness
                             overflow: "hidden",
                         }}
                     >
                         <QRCode
                             value={urlToShare}
                             size={200}
                             fgColor={textcolor}
                             bgColor={userData?.bgColor}
                             level="M"
                             includeMargin={true}
                             renderAs="svg"
                         />
                     </div>
                 </Box>
             </Modal>
             <Box sx={{ height: 1 , transform: 'translateZ(0px)', flexGrow: 1}}  style={{
               bottom: "0px",
               left: "0px",
               whiteSpace: "nowrap",
               zIndex: "0",
               color: `white`,
               
               position:"fixed",
               zIndex:100,
             }}>
     <SpeedDial
     onClick={() => {setOppen(!oppen);setAnimate(!animate)}}
       ariaLabel="SpeedDial basic example"
       
       sx={{ 
         position: 'absolute', 
         bottom: 10, 
         left: 16, 
         marginBottom:"30px",
         zIndex: 40, 
         '& .MuiSpeedDial-fab': {
           backgroundColor: `${userData?.buttonColor} !important`,
           '&:hover': {
             backgroundColor: `${userData?.buttonColor} !important`
                       }
         },
         marginLeft:"-20px"
       }}
      
       style={{color: textbottoncolor}}
       icon={
         <div
           style={{
             border: "2px solid",
             borderColor: textbottoncolor,
             borderRadius: "50%",
             padding: "12px",
           }}
         >
           <PiUploadSimpleBold size={28}       style={{color: textbottoncolor}} />
         </div>
       }
       ref={speedDialRef}
       open={oppen}
     >
           
        <SpeedDialAction
        sx={{
     borderTop: '1px solid grey',
     borderRadius: '8px',
     backgroundColor: userData?.buttonColor,
     padding: '8px',
     marginTop: '-2px', // Reduced margin to make the actions closer
     transition: 'all 0.3s ease-in-out',
     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
     '&:hover': {
       backgroundColor: userData?.buttonColor,
       transform: 'scale(1.05)',
       boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
     },
     width:"15.5vh",
      ...(animate ? { animation: 'slide-from-right 0.7s ease-in-out' } : {}),
   }}
           icon=
           <div className='flex' onClick={handleOpen}>
           <IoQrCodeOutline  style={{color: textbottoncolor}} size={15}  />
            <p
            style={{color: textbottoncolor}}
           className={`text-[8px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>my qr code</p>
           </div>
           
         >
         </SpeedDialAction>
         <SpeedDialAction
          sx={{
     borderTop: '1px solid grey',
     borderRadius: '8px',
     backgroundColor: userData?.buttonColor,
     padding: '8px',
     marginTop: '-2px', // Reduced margin to make the actions closer
     transition: 'all 0.3s ease-in-out',
     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
     '&:hover': {
       backgroundColor: userData?.buttonColor,
       transform: 'scale(1.05)',
       boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
     },
     width:"15.5vh",
      ...(animate ? { animation: 'slide-from-right 0.4s ease-in-out' } : {}),
   }}
           icon=
           <div className='flex '    style={{color: textbottoncolor}} onClick={handleCopyLink}>
           <FaRegCopy size={14}  color={userData?.textbottoncolor}/>
           <p   style={{color: textbottoncolor}} className={`text-[9px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>copy link</p>
           </div>
           > 
           </SpeedDialAction>
           
           <SpeedDialAction
           sx={{
     borderTop: '1px solid grey',
     borderRadius: '8px',
     backgroundColor: userData?.buttonColor,
     padding: '8px',
     marginTop: '-2px', // Reduced margin to make the actions closer
     transition: 'all 0.3s ease-in-out',
     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
     '&:hover': {
       backgroundColor: userData?.buttonColor,
       transform: 'scale(1.05)',
       boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
     },
     width:"15.5vh",
      ...(animate ? { animation: 'slide-from-right 0.3s ease-in-out' } : {}),
   }}
           icon=
           <div className='flex '  onClick={() => setOppen(!oppen)}>
         
         
 
 
           <RWebShare
           data={{
             url: urlToShare,
             title: "Share your TebSocial link",
           }}
           onClick={() => setOppen(!oppen)}
         >
           <div className="flex -ml-4 -mt-2">
           
             <IoShareSocial size={15} className="w-4 h-4 mt-[10px] ml-2 z-50"color={textbottoncolor}/>
 
             <p style={{color: textbottoncolor}} className={`text-[9px] pl-2 mt-[10px] ${animate ? 'animate-slide-from-right' : ''}`}>SHARE LINK</p>
           </div>
         </RWebShare>
           </div>
           > 
           </SpeedDialAction>
     
      
     </SpeedDial>
   </Box>
 
 
         </button>
         {isVisible && (
           <div className="z-50 fixed bottom-16 left-1/2 transform -translate-x-1/2 border-2 bg-gray-700 text-white rounded-xl px-2">
               link copied
           </div>
       )}
 
        </div>
      </div>
      <div classname="" >
          
      <a href="https://www.x-tag.tech/" target='_blank'
      className="absolute   w-screen left-0 bottom-0  mb-10  flex justify-center  "
      style={{ color: textcolor }}
     >
     
     {
       textcolor=='#ffffff'?
     
         <img src={powerdByXTagWhite} className='w-[200px] '/>
         :
         <img src={powerdByXTagBlack} className='w-[200px] '/>
       }
     </a>
     
             </div>
    </>
  );
};

export default Temp18;
