import { IoLogoWhatsapp } from "react-icons/io";
import React, { useEffect, useState, useRef } from "react";
import { FaPhoneVolume } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { BsFiletypePdf } from "react-icons/bs";
import { PiUploadSimpleBold } from "react-icons/pi";
import { FaPlus } from "react-icons/fa";
import { RWebShare } from "react-web-share";
import { IoQrCodeOutline } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import { IoShareSocial } from "react-icons/io5";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import tinycolor from "tinycolor2";
import QRCode from "qrcode.react";
import Modal from "@mui/material/Modal";
import copy from "../assets/pngwing.com (11).png";
import share from "../assets/pngwing.com (9).png";
import { MdEmail } from "react-icons/md";
import qr from "../assets/pngwing.com (10).png";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress"; 
import { pdfjs } from "react-pdf";
import powerdByXTag from '../assets/poweredByXtag.png'
import powerdByXTagWhite from '../assets/poweredbyxtagWhite.png'

import powerdByXTagBlack from '../assets/poweredbyxtagBlack.png'

function Templateone() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userData, setUserData] = useState(null);
  const [urlToShare, seturlToShare] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state variable for loading status
  const [opennnn, setOpennnn] = useState(false);
  const handleCloseeee = () => setOpennnn(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [animate, setAnimate] = useState(false);

  const openLocationInGoogleMaps = (location) => { // Encode the location string to make it URL-safe 
    const encodedLocation = encodeURIComponent(location); // Create the Google Maps URL 
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`; // Open the URL in a new tab 
    window.open(googleMapsUrl, '_blank');
   };


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 270,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let retryCount = 0;
  const maxRetryCount = 3; // Maximum number of retries
  const retryDelay = 2000; // Delay between retries in milliseconds
  const getCurrentUrl = () => {
    seturlToShare(window.location.href);
  };
  const retryFetch = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => { 
        fetchData();
        retryCount++;
      }, retryDelay);
    } else {
      console.error("Maximum retry attempts reached.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUrl();
  }, []);
  const opene = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const speedDialRef = useRef();
  const location = useLocation();

  const [oppen, setOppen] = React.useState(false);
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const userParam = query.get("user");
  const fetchData = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(
        `https://api.tebsocial.com/api/user/${userParam}`
      );

      setUserData(response.data.data);
      console.log(response.data.data);
      document.documentElement.style.setProperty(
        "--bg-color",
        response.data.data.bgColor
      );
      document.documentElement.style.setProperty(
        "--button-bg-color",
        response.data.data.buttonColor
      );
      document.documentElement.style.setProperty(
        "--border-color",
        response.data.data.buttonColor
      );

      if (apicounter == 0) {
        axios.post(
          `https://api.tebsocial.com/api/profile/${response.data.data?.id}/visit`,
          {}
        );
        apicounter++;
        console.log(apicounter);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        console.error("Server responded with error:", error.response.status);

        setIsLoading(false);
      } else if (error.request) {
        console.error("No response received:", error.request);
        retryFetch();
      } else {
        console.error("Error setting up request:", error.message);
        setIsLoading(false);
      }
    }
  };
  console.log("userParam", userParam);
  useEffect(() => {
   

    fetchData();
  }, [userParam]);
  let apicounter = 0;

  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");

  useEffect(() => {
    const mainBackgroundColor = userData?.bgColor;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.bgColor]);

  useEffect(() => {
    const buttonsBackground = userData?.buttonColor;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.buttonColor]);
  const saveAsPDF = async () => {
    const targetComponent = document.getElementById("mainComponent");
    if (targetComponent) {
      try {
        const canvas = await html2canvas(targetComponent);
        const imgData = canvas.toDataURL("image/png");

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const { width, height } = page.getSize();
        const img = await pdfDoc.embedPng(imgData);
        const { width: imgWidth, height: imgHeight } = img.scale(1);

        page.drawImage(img, {
          x: 0,
          y: 0,
          width: imgWidth,
          height: imgHeight,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Use FileSaver.js library to save the blob as a file
        const saveAs = require("file-saver");
        saveAs(blob, "screenshot.pdf");
      } catch (error) {
        console.error("Error saving as PDF:", error);
      }
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const params = new URLSearchParams(window.location.search);

  const profileValue = params.get("profile");

  console.log("Profile:", profileValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopyLink = () => {
    const linkToCopy = urlToShare;

    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
    // toast.success('Link copied!');

    console.log("Link copied to clipboard:", linkToCopy);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 5000 milliseconds (5 seconds)

    // You can add additional logic or feedback here if needed
  };

  const handleDocumentClick = (e) => {
    if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
     
      setOppen(false);
    }
  };
  const toDataURL = (url) =>
    axios
      .get(url, {
        responseType: "blob",
        headers: {},
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(response.data);
        });
      })
      .catch((error) => {
        console.error("Error in toDataURL with axios:", error);
        return null;
      });

  const saveToContacts = async () => {
    const contact = {
      name: userData?.firstName + " " + userData?.lastName,
      mobile: userData?.phoneNum || "",
      email: userData?.email || "",
      address: userData?.location || "",
      organization: userData?.businessName || "",
      TebSocialWebsite: urlToShare,
      photo: userData?.photo || "",
      primary_links: userData?.primary_links || [],
    };

    let imageDataUrl = "";
    if (contact.photo !== "") {
      imageDataUrl = await toDataURL(contact.photo);
      console.log("imageDataUrl,imageDataUr", imageDataUrl);
      if (!imageDataUrl) {
        console.error("Failed to convert image to Data URL");
        return;
      }
    }

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `item1.URL:${contact.xTagWebsite}\n` +
      `item1.X-ABLabel:Teb Social WEBSITE\n` +
      `PHOTO;ENCODING=b;TYPE=JPEG:${imageDataUrl.split(",")[1]}\n`;

    contact?.primary_links?.forEach((link, index) => {
      const itemNumber = index + 2; // Starting from 2 as 1 is used for TebSocialWebsite
      vcard +=
        `item${itemNumber}.URL:${link.value}\n` +
        `item${itemNumber}.X-ABLabel:${link.name}\n`;
    });

    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contact.name}.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID

    try {
      await axios.post(
        `https://api.tebsocial.com/api/link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  const handlePrimaryLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID
console.log('primaryItem',primaryItem)
    try {
      await axios.post(
        `https://api.tebsocial.com/api/${userData.id}/primary_link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };



  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress /> {/* Loading spinner */}
      </div>
    );
  }
  return (
    <>
      <div
        className="min-h-screen bg-[var(--bg-color)] relative pb-5 overflow-hidden mb-10"
        id="mainComponent"
      >
        <div
          className="fixed w-screen h-screen -z-10  "
          style={{ backgroundColor: userData?.bgColor }}
        ></div>
        <div onClick={saveToContacts}


        style={{ backgroundColor: userData?.buttonColor ,color: textbottoncolor,border:"2px solid",borderColor:textbottoncolor}}
       className='flex items-center  fixed right-2 rounded-full mb-2 bottom-[35px] text-center text-xs px-1 z-50'>
       <div 
       style={{ backgroundColor: userData?.buttonColor,borderColor:textbottoncolor}}
           className='w-fit py-3 px-3 rounded-full -ml-2 border-2  p-1'><FaPlus color={userData?.textbottoncolor} size={19} className=''/></div>
             <p
             
             className=" whitespace-nowrap pl-[2px] text-[14px] font-bold " style={{color: textbottoncolor,fontWeight:'bold'}}>Add To Contacts</p>   
             </div>
     

             <button className="fixed z-50 bottom-4  left-1 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)]">
             <Modal
                 open={open}
                 onClose={handleClose}
                 aria-labelledby="modal-modal-title"
                 aria-describedby="modal-modal-description"
             >
                 <Box
                     sx={{
                         position: "absolute",
                         top: "50%",
                         left: "50%",
                         transform: "translate(-50%, -50%)",
                         width: 260,
                         p: 4,
                         
                     }}
                 >
                     <div
                         style={{
                             borderRadius: "20px", // Adjust as needed for roundness
                             overflow: "hidden",
                         }}
                     >
                         <QRCode
                             value={urlToShare}
                             size={200}
                             fgColor={textcolor}
                             bgColor={userData?.bgColor}
                             level="M"
                             includeMargin={true}
                             renderAs="svg"
                         />
                     </div>
                 </Box>
             </Modal>
             <Box sx={{ height: 1 , transform: 'translateZ(0px)', flexGrow: 1}}  style={{
               bottom: "0px",
               left: "0px",
               whiteSpace: "nowrap",
               zIndex: "0",
               color: `white`,
               marginBottom:"30px",
               position:"fixed",
               zIndex:100,
             }}>
     <SpeedDial
     onClick={() => {setOppen(!oppen);setAnimate(!animate)}}
       ariaLabel="SpeedDial basic example"
       
       sx={{ 
         position: 'absolute', 
         bottom: 10, 
         left: 16, 
         zIndex: 40, 
         '& .MuiSpeedDial-fab': {
           backgroundColor: `${userData?.buttonColor} !important`,
           '&:hover': {
             backgroundColor: `${userData?.buttonColor} !important`
                       }
         },
         marginLeft:"-20px"
       }}
      
       style={{color: textbottoncolor}}
       icon={
         <div
           style={{
             border: "2px solid",
             borderColor: textbottoncolor,
             borderRadius: "50%",
             padding: "12px",
           }}
         >
           <PiUploadSimpleBold size={28}       style={{color: textbottoncolor}} />
         </div>
       }
       ref={speedDialRef}
       open={oppen}
     >
           
        <SpeedDialAction
        sx={{
     borderTop: '1px solid grey',
     borderRadius: '8px',
     backgroundColor: userData?.buttonColor,
     padding: '8px',
     marginTop: '-2px', // Reduced margin to make the actions closer
     transition: 'all 0.3s ease-in-out',
     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
     '&:hover': {
       backgroundColor: userData?.buttonColor,
       transform: 'scale(1.05)',
       boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
     },
     width:"15.5vh",
      ...(animate ? { animation: 'slide-from-right 0.7s ease-in-out' } : {}),
   }}
           icon=
           <div className='flex' onClick={handleOpen}>
           <IoQrCodeOutline  style={{color: textbottoncolor}} size={15}  />
            <p
            style={{color: textbottoncolor}}
           className={`text-[8px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>my qr code</p>
           </div>
           
         >
         </SpeedDialAction>
         <SpeedDialAction
          sx={{
     borderTop: '1px solid grey',
     borderRadius: '8px',
     backgroundColor: userData?.buttonColor,
     padding: '8px',
     marginTop: '-2px', // Reduced margin to make the actions closer
     transition: 'all 0.3s ease-in-out',
     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
     '&:hover': {
       backgroundColor: userData?.buttonColor,
       transform: 'scale(1.05)',
       boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
     },
     width:"15.5vh",
      ...(animate ? { animation: 'slide-from-right 0.4s ease-in-out' } : {}),
   }}
           icon=
           <div className='flex '    style={{color: textbottoncolor}} onClick={handleCopyLink}>
           <FaRegCopy size={14}  color={userData?.textbottoncolor}/>
           <p   style={{color: textbottoncolor}} className={`text-[9px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>copy link</p>
           </div>
           > 
           </SpeedDialAction>
           
           <SpeedDialAction
           sx={{
     borderTop: '1px solid grey',
     borderRadius: '8px',
     backgroundColor: userData?.buttonColor,
     padding: '8px',
     marginTop: '-2px', // Reduced margin to make the actions closer
     transition: 'all 0.3s ease-in-out',
     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
     '&:hover': {
       backgroundColor: userData?.buttonColor,
       transform: 'scale(1.05)',
       boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
     },
     width:"15.5vh",
      ...(animate ? { animation: 'slide-from-right 0.3s ease-in-out' } : {}),
   }}
           icon=
           <div className='flex '  onClick={() => setOppen(!oppen)}>
         
         
 
 
           <RWebShare
           data={{
             url: urlToShare,
             title: "Share your TebSocial link",
           }}
           onClick={() => setOppen(!oppen)}
         >
           <div className="flex -ml-4 -mt-2">
           
             <IoShareSocial size={15} className="w-4 h-4 mt-[10px] ml-2 z-50"color={textbottoncolor}/>
 
             <p style={{color: textbottoncolor}} className={`text-[9px] pl-2 mt-[10px] ${animate ? 'animate-slide-from-right' : ''}`}>SHARE LINK</p>
           </div>
         </RWebShare>
           </div>
           > 
           </SpeedDialAction>
     
      
     </SpeedDial>
   </Box>
 
 
         </button>
         {isVisible && (
           <div className="z-50 fixed bottom-16 left-1/2 transform -translate-x-1/2 border-2 bg-gray-700 text-white rounded-xl px-2">
               link copied
           </div>
       )}
 

       <div className="relative h-auto w-full">
       <div className="relative w-full">
         <img
           src={userData?.cover}
           alt="Cover Photo"
           className="object-cover w-full h-full " // Adjusts height based on screen size
         />
       </div>
     
       <div className="flex justify-center items-center z-50">
         {userData?.photo &&
         typeof userData.photo === "string" &&
         userData.photo.trim() !== "" ? (
           <div
             className="w-[100%] h-10 flex justify-between flex-row-reverse relative"
             style={{ background: userData?.buttonColor }}
           >
             <div className={`relative w-[50%]`}>
               <div
                 className="line bent z-10 bg-[var(--button-bg-color)] border-[var(--button-bg-color)] 2sm:rotate-[-4deg] 3sm:rotate-[-2deg] 4sm:rotate-[-1deg] md:rotate-[-1deg]"
                 style={{ background: userData?.buttonColor }}
               ></div>
               <div
                 className="absolute z-20 rounded-l-full top-3 left-[10px] w-[120%] h-[240%] "
                 style={{
                   background: userData?.bgColor,
                   transform: "rotate(0deg)",
                 }}
               ></div>
               <div
                 className="line bent z-20 absolute top-[8px] left-[5px] md:left-1 "
                 style={{
                   background: userData?.bgColor,
                   transform: "rotate(0.5deg)",
                 }}
               ></div>
             </div>
     
             <div className={`relative w-[50%]`}>
               <div
                 className="line bent2 z-10 bg-[var(--button-bg-color)] border-[var(--button-bg-color)] 2sm:rotate-[4deg] 3sm:rotate-[2deg] 4sm:rotate-[1deg] md:rotate-[1deg]"
                 style={{ background: userData?.buttonColor }}
               ></div>
               <div
                 className="absolute z-20 rounded-l-full top-3 right-[10px] w-[120%] h-[240%] "
                 style={{
                   background: userData?.bgColor,
                   transform: "rotate(0deg)",
                 }}
               ></div>
               <div
                 className="line bent2 z-20 absolute top-[8px] right-10 "
                 style={{
                   background: userData?.bgColor,
                   transform: "rotate(0.5deg)",
                 }}
               ></div>
             </div>
           </div>
         ) : (
           ""
         )}
     
         {userData?.photo &&
           typeof userData.photo === "string" &&
           userData.photo.trim() !== "" && (
             <>
               <img
                 style={{ background: userData?.buttonColor }}
                 className="absolute w-32 h-32 sm:w-40 sm:h-40 rounded-full sm:-bottom-16 -bottom-14 border-4 lgx:w-44 lgx:h-44 border-[var(--border-color)] shadow-md z-50"
                 src={`${userData?.photo}`}
                 alt="User Photo"
                 onClick={handleImageClick}
               />
               {isModalOpen && (
                 <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 z-50 overflow-auto flex items-center justify-center">
                   <div className="relative z-50">
                     <img
                       src={`${userData?.photo}`}
                       alt="User Photo"
                       className="rounded-full shadow-md"
                       style={{ width: "250px", height: "250px" }}
                     />
                     <button
                       onClick={handleModalClose}
                       className="absolute -top-48 -right-10 p-2 text-white text-4xl"
                     >
                       x
                     </button>
                   </div>
                 </div>
               )}
             </>
           )}
       </div>
     </div>
     
     
        <div className="flex justify-around mt-24 z-50">
        <button
          className="text-[40px] md:text-[50px] "
          onClick={() => {
            const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
            window.open(`https://wa.me/${cleanedPhoneNum}`);
          }}
        >
          <IoLogoWhatsapp color={textcolor} />
        </button>
        <button
          className="text-[40px] md:text-[50px] "
          onClick={() => {
            window.open(`mailto:${userData.email}`);
          }}
        >
          <MdEmail color={textcolor} />
        </button>
        <button
          className="text-[40px] md:text-[50px]  r rotate-140"
          onClick={() => {
            const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
            window.open(`tel:${cleanedPhoneNum}`);
            // window.open(`tel:${userData.phoneNum}`);
          }}
        >
          <FaPhoneVolume color={textcolor} size={35} className=' -rotate-[30deg]' />
        </button> 
      </div>
        <div className="flex flex-col justify-center items-center text-center mt-16 z-20 relative ">
          <h2
            className="text-2xl 2sm:text-3xl  Robote_bold z-20 w-full "
            style={{ color: textcolor,fontSize: "2rem",fontWeight:"bold" }}
          >
            {userData?.firstName !=="undefined" && userData?.firstName !=="null" &&
            userData?.firstName !==undefined && userData?.firstName !==null? userData?.firstName : ""}{" "}

            {userData?.lastName !=="undefined" && userData?.lastName !=="null" &&
            userData?.lastName !==undefined && userData?.lastName !==null? userData?.lastName : ""}{" "}

      
          </h2>
          <div className="mt-2 z-20">
            {userData?.jobTitle !== null &&
            userData?.jobTitle !== "null" &&
            userData?.jobTitle !== "undefined" &&
            userData?.jobTitle !== undefined ? (
              <h2
                className="text-sm 2sm:text-lg font-semibold Robote"
                style={{ color: textcolor }}
              >
                {userData?.jobTitle}
              </h2>
            ) : (
              ""
            )}
            {userData?.businessName !== null &&
            userData?.businessName !== "null" &&
            userData?.businessName !== "undefined" &&
            userData?.businessName !== undefined ? (
              <h2
                className="text-xs 2sm:text-md font-semibold Robote"
                style={{ color: textcolor }}
              >
                {userData?.businessName}
              </h2>
            ) : (
              ""
            )}
            {userData?.location !== null &&
            userData?.location !== "null" &&
            userData?.location !== "undefined" &&
            userData?.location !== undefined ? (
              <h2
                className="text-xs 2sm:text-md font-semibold Robote cursor-pointer"
                style={{ color: textcolor }}
                onClick={()=>{openLocationInGoogleMaps(userData?.location)}}
              >
                {userData?.location}
              </h2>
            ) : (
              ""
            )}
          </div>

          
          {userData?.bio !== null && userData?.bio !== "null" && (
            <h2
              className="w-[70%] mt-3 text-sm md:text-lg font-semibold Robote"
              style={{ color: textcolor }}
            >
              {userData?.bio ? userData?.bio : ""}
            </h2>
          )}
        </div>

    

        <div className="grid grid-cols-3 justify-center items-center gap-y-7 mt-20 place-items-center">
          {userData?.primary_links?.map((primaryItem, primaryIndex) => (
            <div
              className="animate-shakeX"
              key={primaryIndex}
              style={{
                width: "50px", // Fixed width for the container
                height: "50px", // Fixed height for the container
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {userData?.primary_links?.[primaryIndex]?.name !== "Telegram" ? (
                <a
                  href={primaryItem?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handlePrimaryLinkClick(primaryItem);
                  }}
                >
                  <img
                    src={primaryItem?.logo}
                    alt="logo"
                    style={{
                      width: "100%", // Stretch the logo to fill the container width
                      height: "100%", // Stretch the logo to fill the container height
                      objectFit: "fill", // This will stretch the image to fill the container
                    }}
                  />
                </a>
              ) : (
                <a
                  href={primaryItem?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handlePrimaryLinkClick(primaryItem);
                  }}
                >
                  <img
                    onClick={() => {
                      const cleanedPhoneNum = userData.phoneNum.replace(
                        /\s+/g,
                        ""
                      ); 
                      const telegramUsername =
                        userData?.primary_links?.[primaryIndex]?.value; // Replace with the actual username or ID
                      window.open(`https://t.me/${telegramUsername}`);
                    }}
                    src={primaryItem?.logo}
                    on
                    alt="logo"
                    style={{
                      width: "100%", // Stretch the logo to fill the container width
                      height: "100%", // Stretch the logo to fill the container height
                      objectFit: "fill", // This will stretch the image to fill the container
                    }}
                  />
                </a>
              )}
            </div>
          ))}

        
        </div>
        <div className="flex justify-center items-center mt-14">
          <div className="w-[70%] flex flex-col gap-3">
            {userData?.section.map((item, index) => {
              const url = item.media;
              const isPDF = url.endsWith(".pdf");

              return (
                <>
                  {!isPDF && (
                    <div className="flex justify-center">
                      <h2
                        className="text-2xl md:text-3xl Robote_bold font-bold"
                        style={{ color: textcolor,fontWeight:"bold" }}
                      >
                        {item.title}
                      </h2>
                    </div>
                  )}

                  <div
                    key={index}
                    className="flex flex-col gap-2 justify-center items-center"
                  >
                  {isPDF ? (
                    <div className="flex flex-col items-center justify-center mb-14">
                    <h2
                      className="text-2xl md:text-3xl Robote_bold p-4"
                      style={{ color: textcolor }}
                    >
                      {item.title}
                    </h2>
                    <div className='w-[250px]  rounded-[10px] border-4' style={{backgroundColor:userData?.buttonColor,borderColor:textcolor,borderRadius: "20px"}}>
                    <a href={url} target='_blank'  >
                        <div className='flex justify-center  w-full    shadow-lg  relative'  >
                        <div className='flex flex-row gap-5'>
                      <BsFiletypePdf
                                      size={90}
                                      className="w-full mt-5 mb-5 "
                                      color={textbottoncolor}
                                    />
        
                        </div>
                        <div className='absolute top-3 right-3'>
                        <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                        <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                        <path fill={textbottoncolor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                        </svg>
                        </div>
                        </div>
                    </a>
                </div>

                  </div>


                ): (
                      <div className=" flex justify-center ">
                      <video
                      className={`rounded-3xl border-4 min-w-[250px] h-[150px] aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                      style={{
                        borderColor: `${textcolor}`,
                        backgroundColor: `${userData?.buttonColor}`,
                      }}
                      controls
                      autoPlay 
                      muted  // This will mute the video, enabling it to autoplay in more browsers
                      preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                  
                      autoplay
                    >
                      <source src={url} type="video/mp4" />
                    </video>
                    
                    
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        
      </div>
      <a href="https://www.x-tag.tech/" target='_blank'
          className="absolute   w-screen left-0  mb-10  flex justify-center  "
          style={{ color: textcolor }}
        >
        {
          textcolor=='#ffffff'?
          <img src={powerdByXTagWhite} className='w-[200px] '/>
          :
          <img src={powerdByXTagBlack} className='w-[200px] '/>
        }
      
        
        </a>
    </>
  );
}

export default Templateone;
